import React from 'react'
import { HeaderMain } from "../header/headerMain.jsx";
import { Outlet, useLocation } from 'react-router-dom';
import FooterSection from '../footer/FooterSection.jsx';

export const Layout = () => {
  const location = useLocation();

  const isProjectPage = location.pathname.startsWith("/project-detail");

  return (
    <div className="layout-body">
      <HeaderMain />
      <Outlet />
      <FooterSection />
    </div>
  );
}

export default Layout