import React, { useState } from "react";
import Villa5br from "../../../assets/DamacIsland/5brV.webp";
import Villa6br from "../../../assets/DamacIsland/6brV.webp";
import Villa7br from "../../../assets/DamacIsland/7brV.webp";
import PopupModel from "../../model/PopupModel";

export const VillaFloorPlan = () => {
    const [activeTab, setActiveTab] = useState("Tab2");
    const [showPopup, setShowPopup] = useState(false);
    const [isOverlayVisible, setIsOverlayVisible] = useState(true);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
    };
    
     const handleViewPlanClick = () => {
       setShowPopup(true);
     };

     const handleFormSubmit = () => {
       setIsOverlayVisible(false);
       setShowPopup(false); // Optionally close the popup after submission
     };

  return (
    <div className="overflow-hidden ml-1">
      <div className="tabs">
        <button
          className={`tab-btn !ml-0 z-30 ${
            activeTab === "Tab2" ? "active" : ""
          }`}
          onClick={() => handleTabClick("Tab2")}
        >
          5 Bedroom
        </button>
        <button
          className={`tab-btn z-20 ${activeTab === "Tab1" ? "active" : ""}`}
          onClick={() => handleTabClick("Tab1")}
        >
          6 Bedroom
        </button>
        <button
          className={`tab-btn z-10 ${activeTab === "Tab3" ? "active" : ""}`}
          onClick={() => handleTabClick("Tab3")}
        >
          7 Bedroom
        </button>
      </div>
      <div className="tab-content bg-[#D9D9D9] p-1 md:p-3">
        {activeTab === "Tab1" && (
          <div className="m-2 relative">
            {isOverlayVisible && (
              <div
                onClick={handleViewPlanClick}
                className="w-full h-[100%] absolute backdrop-blur-sm cursor-pointer flex items-center justify-center"
              >
                <div className="relative">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <button className="px-9 py-2 bg-sky-400 rounded-full  m-auto text-[#000] text-[0.8rem] md:text-[1rem]">
                    View Floor Plan
                  </button>
                </div>
              </div>
            )}
            <div className="w-[100%] h-fit text-center bg-[#ffffff]">
              <img
                className="h-[100%] w-full m-auto"
                src={Villa6br}
                alt="floor plan"
              />
            </div>
          </div>
        )}
        {activeTab === "Tab2" && (
          <div className="m-2 relative">
            {isOverlayVisible && (
              <div
                onClick={handleViewPlanClick}
                className="w-full h-[100%] absolute backdrop-blur-sm cursor-pointer flex items-center justify-center"
              >
                <div className="relative">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <button className="px-9 py-2 bg-sky-400 rounded-full  m-auto text-[#000] ">
                    View Floor Plan
                  </button>
                </div>
              </div>
            )}
            <div className="w-[100%] h-fit text-center bg-[#ffffff]">
              <img
                className="h-[100%] w-full m-auto"
                src={Villa5br}
                alt="floor plan"
              />
            </div>
          </div>
        )}
        {activeTab === "Tab3" && (
          <div className="m-2 relative">
            {isOverlayVisible && (
              <div
                onClick={handleViewPlanClick}
                className="w-full h-[100%] absolute backdrop-blur-sm cursor-pointer flex items-center justify-center"
              >
                <div className="relative">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                  <button className="px-9 py-2 bg-sky-400 rounded-full  m-auto text-[#000] ">
                    View Floor Plan
                  </button>
                </div>
              </div>
            )}
            <div className="w-[100%] h-fit text-center bg-[#ffffff]">
              <img
                className="h-[100%] w-full m-auto"
                src={Villa7br}
                alt="floor plan"
              />
            </div>
          </div>
        )}
      </div>
      <div>
        {showPopup && (
          <PopupModel
            onClose={() => setShowPopup(false)}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default VillaFloorPlan;
