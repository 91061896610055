import React, { useState } from 'react'
import PopupModel from '../../model/PopupModel';
import ContactForm from '../../pages/contact/component/ContactForm';

export const FormBanner = () => {
  const [ShowPopup, setShowPopup] = useState(false);
  return (
    <div className="container max-w-[1240px] px-4 flex items-center justify-between m-auto mt-[-40px] md:mt-[-100px] relative">
      <div className="rouded drop-shadow-xl  bg-[#ffffffbc] z-40 rounded-[20px] backdrop-blur">
        <div className="px-[20px] py-[30px] md:px-[50px] md:py-[50px] grid md:grid-cols-2">
          <div>
            <div className="banner-content z-10">
              <h1 className="banner-h1 text-[#18A4A0] text-[1.1rem] sm:text-[1.3rem] md:text-[1.7rem]">
                4 - 5 BR Waterfront Townhouses &
              </h1>
              <h1 className="banner-h1 text-[#18A4A0] text-[1.1rem] sm:text-[1.3rem] md:text-[1.7rem]">
                5 - 7 BR Waterfront Independent Villas in Dubai
              </h1>
              <h2 className="text-[0.9rem] md:text-[1.2rem] font-semibold capitalize">
                Townhouses From 2.5M AED | $680K USD
              </h2>
              <h2 className="text-[0.9rem] md:text-[1.2rem] font-semibold capitalize">
                Villas From 6.5M AED | $1.7M USD
              </h2>
              <h2 className="text-[0.9rem] md:text-[1.2rem] font-semibold capitalize pb-1 md:pb-4">
                1% monthly payment plan
              </h2>
              <p className="mb-1 md:mb-4">
                Mediterranean-inspired villas and townhouses in DAMAC Island
              </p>
              <button onClick={() => setShowPopup(true)} className="site-btn ">
                Request callback
              </button>
            </div>
          </div>

          <div>
            <ContactForm />
          </div>
        </div>
      </div>
      <div>
        {ShowPopup && <PopupModel onClose={() => setShowPopup(false)} />}
      </div>
    </div>
  );
}

export default FormBanner