import React, { useState } from 'react'
import Slider from "react-slick";
import slidImg1 from "../../../assets/DamacIsland/aminitimg1.webp"
import slidImg2 from "../../../assets/DamacIsland/aminitimg2.webp";
import slidImg3 from "../../../assets/DamacIsland/aminitimg3.webp";
import slidImg4 from "../../../assets/DamacIsland/aminitimg4.webp";
import slidImg5 from "../../../assets/DamacIsland/aminitimg5.webp";
import PopupModel from "../../model/PopupModel";

export const AmenitiesImg = () => {
  const [ShowPopup, setShowPopup] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,

        settings: {
          slidesToShow: 4,

          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 640,

        settings: {
          slidesToShow: 2,

          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div id="Amenities" className="container max-w-[1240px] py-6  px-4 m-auto">
      <h1 className="text-[#000000] m-auto w-fit">Amenities</h1>
      <p className="w-[100%] md:w-[80%] text-center m-auto mb-[20px] md:mb-[30px]">
        Immerse yourself in the sounds, aromas, cuisine. Come discover a rich
        tapestry of vibrant amenities that turn every day in a magical
        experience. 
      </p>
      <Slider {...settings}>
        <div className="px-1">
          <div
            className=" w-full h-[300px] md:h-[450px] bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden"
            style={{ backgroundImage: `url(${slidImg1})` }}
            onClick={() => setShowPopup(true)}
          >
            <div className="w-full h-[160px] absolute left-0 bottom-0 bg-gradient-to-t from-[#18A4A0] flex items-end justify-center">
              <h3 className="text-[#ffff] text-[1.2rem] font-semibold pb-6">
                River Side
              </h3>
            </div>
          </div>
        </div>
        <div className="px-1 ">
          <div
            className=" w-full h-[300px] md:h-[450px] bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden"
            style={{ backgroundImage: `url(${slidImg2})` }}
            onClick={() => setShowPopup(true)}
          >
            <div className="w-full h-[160px] absolute left-0 bottom-0 bg-gradient-to-t from-[#18A4A0] flex items-end justify-center">
              <h3 className="text-[#ffff] text-[1.2rem] font-semibold pb-6">
                Green Land
              </h3>
            </div>
          </div>
        </div>
        <div className="px-1">
          <div
            className=" w-full h-[300px] md:h-[450px] bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden"
            style={{ backgroundImage: `url(${slidImg3})` }}
            onClick={() => setShowPopup(true)}
          >
            <div className="w-full h-[160px] absolute left-0 bottom-0 bg-gradient-to-t from-[#18A4A0] flex items-end justify-center">
              <h3 className="text-[#ffff] text-[1.2rem] font-semibold pb-6">
                Outdoor Pool
              </h3>
            </div>
          </div>
        </div>
        <div className="px-1">
          <div
            className=" w-full h-[300px] md:h-[450px] bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden"
            style={{ backgroundImage: `url(${slidImg4})` }}
            onClick={() => setShowPopup(true)}
          >
            <div className="w-full h-[160px] absolute left-0 bottom-0 bg-gradient-to-t from-[#18A4A0] flex items-end justify-center">
              <h3 className="text-[#ffff] text-[1.2rem] font-semibold pb-6">
                Kids Play Area
              </h3>
            </div>
          </div>
        </div>
        <div className="px-1">
          <div
            className=" w-full h-[300px] md:h-[450px] bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden"
            style={{ backgroundImage: `url(${slidImg5})` }}
            onClick={() => setShowPopup(true)}
          >
            <div className="w-full h-[160px] absolute left-0 bottom-0 bg-gradient-to-t from-[#18A4A0] flex items-end justify-center">
              <h3 className="text-[#ffff] text-[1.2rem] font-semibold pb-6">
                Gym
              </h3>
            </div>
          </div>
        </div>
      </Slider>
      <div>
        {ShowPopup && <PopupModel onClose={() => setShowPopup(false)} />}
      </div>
    </div>
  );
};

export default AmenitiesImg;