import React, { useEffect, useState } from 'react'
import projectCover from "../../../../assets/DamacIsland/banner01.png";
import ProjectLogo from "../../../../assets/DamacIsland/ProjectLogo-cust.png";
import { TbPointFilled } from "react-icons/tb";
import { IoLogoWhatsapp } from "react-icons/io";
import { PopupModel } from "../../../model/PopupModel"; 
import Slider from 'react-slick';

export const ProjectBanner = () => {
  const [ShowPopup, setShowPopup] = useState(false);

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
    };

  return (
    <div className="relative">
      <div
        className="banner banner1 w-full bg-[#040406] flex items-center justify-start"
        role="img"
        aria-label="Damac Island"
        style={{
          backgroundImage: `url(${projectCover})`,
        }}
      >
        <div className="container max-w-[1240px] px-4 flex items-center justify-center z-10 relative">
          <div className="banner-content h-[300px] md:h-[500px] lg:h-[700px]"></div>
        </div>
      </div>
      <div className="bg-gradient-to-t from-[#ffff] w-full h-[50px] md:h-[100px] absolute z-10 bottom-0 left-0"></div>

      <div>
        {ShowPopup && <PopupModel onClose={() => setShowPopup(false)} />}
      </div>
    </div>
  );
}

export default ProjectBanner