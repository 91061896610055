import './App.scss';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {Layout} from './component/layout/Layout'
import ScrollViewTop from './hooks/ScrollViewTop';
import { Helmet } from 'react-helmet';
import DamacIsland from './component/campainPage/DamacIsland';


function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Damac Island | Offplan Projects - Apartments, Villas, Townhouses, Penthouses</title>
        <meta name="keywords" content="Damac Island, Damac Properties, Damac villa, Damac, New Developments, Off Plan, New Developments in Dubai, Off Plan Projects, Offplan Projects, Off Plan in Dubai, Buy Apartments in Dubai, Buy Villas in Dubai, Buy Townhouses in Dubai, Sale Apartments in Dubai, Sale Villas in Dubai, Sale Townhouses in Dubai, DNK Real Estate, Properties in Dubai, Rent Properties in Dubai, Rent in Dubai, New Off Plan Project, Upcoming Off Plan Properties, New Launch Off-Plan Properties, Dubai Properties Projects, Dubai Real Estate, Real Estate Projects in Dubai, Real Estate Projects in UAE, DNK Real Estate, Real Estate Information, Dubai Developers, Dubai Communities, New Launches, Under Constructions, Ready to Move, Apartment, Villa, Townhouses, Studio, Business Space in Dubai, Office Space in Dubai, Office Space in Business bay Dubai, luxury apartments Dubai, Best Real Estate Company Dubai, Dubai Investment, Dubai Real Estate Market, Downtown Dubai." />
        <meta name="description" content="Damac Islands is an exclusive new master community developed by the renowned Damac Properties, offering a premium collection of luxurious residences in Dubai. This newly launched development elevates luxury living by blending natural beauty with state-of-the-art conveniences. Nestled adjacent to the prestigious Sun City community, Damac Islands offers a unique, water-centric lifestyle that transports its residents to a serene coastal paradise." />
        <link rel="icon" href="https://www.damac-properties.co/logo.webp" />
        {/* <link rel="canonical" href="https://dnkre.com" /> */}
        <meta name="author" content="Damac Properties" />
        
        <link rel="apple-touch-icon" href="https://www.damac-properties.co/logo.webp" />

        {/* -- Open Graph Meta Tags for WhatsApp and Social Media Sharing -- */}
        <meta property="og:title" content="Damac Island | Offplan Projects - Apartments, Villas, Townhouses, Penthouses" />
        <meta property="og:description" content="Damac Islands is an exclusive new master community developed by the renowned Damac Properties, offering a premium collection of luxurious residences in Dubai. This newly launched development elevates luxury living by blending natural beauty with state-of-the-art conveniences. Nestled adjacent to the prestigious Sun City community, Damac Islands offers a unique, water-centric lifestyle that transports its residents to a serene coastal paradise." />
        <meta property="og:image" content="https://www.damac-properties.co/logo.webp" />

        {/* -- Twitter Card for Sharing -- */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Damac Island | Offplan Projects - Apartments, Villas, Townhouses, Penthouses" />
        <meta name="twitter:description" content="Damac Islands is an exclusive new master community developed by the renowned Damac Properties, offering a premium collection of luxurious residences in Dubai. This newly launched development elevates luxury living by blending natural beauty with state-of-the-art conveniences. Nestled adjacent to the prestigious Sun City community, Damac Islands offers a unique, water-centric lifestyle that transports its residents to a serene coastal paradise." />
        <meta name="twitter:image" content="https://www.damac-properties.co/logo.webp" />

        {/* Schema Markup for Website */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "@id": "#website",
            "headline": "Damac Island | Offplan Projects - Apartments, Villas, Townhouses, Penthouses",
            "keywords": "Damac Island, Damac Properties, Damac villa, Damac, New Developments, Off Plan, New Developments in Dubai, Off Plan Projects, Offplan Projects, Off Plan in Dubai, Buy Apartments in Dubai, Buy Villas in Dubai, Buy Townhouses in Dubai, Sale Apartments in Dubai, Sale Villas in Dubai, Sale Townhouses in Dubai, DNK Real Estate, Properties in Dubai, Rent Properties in Dubai, Rent in Dubai, New Off Plan Project, Upcoming Off Plan Properties, New Launch Off-Plan Properties, Dubai Properties Projects, Dubai Real Estate, Real Estate Projects in Dubai, Real Estate Projects in UAE, DNK Real Estate, Real Estate Information, Dubai Developers, Dubai Communities, New Launches, Under Constructions, Ready to Move, Apartment, Villa, Townhouses, Studio, Business Space in Dubai, Office Space in Dubai, Office Space in Business bay Dubai, luxury apartments Dubai, Best Real Estate Company Dubai, Dubai Investment, Dubai Real Estate Market, Downtown Dubai.",
            "image": "https://www.damac-properties.co/logo.webp",
            "description": "Damac Islands is an exclusive new master community developed by the renowned Damac Properties, offering a premium collection of luxurious residences in Dubai. This newly launched development elevates luxury living by blending natural beauty with state-of-the-art conveniences. Nestled adjacent to the prestigious Sun City community, Damac Islands offers a unique, water-centric lifestyle that transports its residents to a serene coastal paradise.",
            "telephone": "+971-1234-5678",
            "inLanguage": {
              "@type": "Language",
              "name": ["Arabic", "English","Hindi"]
            },
          })}
        </script>

        {/* Schema Markup for Organization */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Damac Island | Offplan Projects - Apartments, Villas, Townhouses, Penthouses",
            "logo": "https://www.damac-properties.co/logo.webp",
            
          })}
        </script>

      </Helmet>

      <ScrollViewTop />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<DamacIsland />} />
          <Route path='/damacisland' element={<DamacIsland />} />
          <Route path='/damacisland/paymentplan' element={<DamacIsland />} />
          <Route path='/damacisland/floorplan' element={<DamacIsland />} />
          <Route path='/damacisland/amenities' element={<DamacIsland />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
