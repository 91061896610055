import React from 'react'
import aboutImg from '../../../../assets/DamacIsland/Aboutimg.png'
import { useNavigate } from 'react-router-dom';

  

export const AboutSection = () => {
  const navigate = useNavigate();
  
  //navigation
  const goToAboutPage = () => {
    navigate("/about");
  };
  return (
    <div
      id="about"
      className="about-section w-full bg-[#ffffff] flex items-center justify-center"
    >
      <div className="container max-w-[1240px] py-6  px-4">
        <h1 className="text-[#000000] m-auto w-fit mb-4 mt-3">
          Elevate your Lifestyle at Damac Island
        </h1>
        <div className="  md:py-9 grid  md:grid-cols-2 relative">
          <img
            src={aboutImg}
            alt="about image"
            className="w-[80%] order-last md:order-first md:w-[80%] m-auto"
            loading="lazy"
          />
          <div>
            <p className="text-justify">
              Damac Islands is an exclusive new master community developed.
              offering a premium collection of luxurious residences in Dubai.
              This newly launched development elevates luxury living by blending
              natural beauty with state-of-the-art conveniences. Nestled
              adjacent to the prestigious Sun City community, Damac Islands
              offers a unique, water-centric lifestyle that transports its
              residents to a serene coastal paradise.
            </p>
            <p className="text-justify">
              Located at one of Dubai’s most coveted addresses, the community
              boasts a unique waterfront living experience with pristine sandy
              beaches, expansive crystalline lagoons, and breathtaking views.
              Designed to be more than just a residential hub, it offers an
              immersive living environment, where every aspect reflects grandeur
              and luxury. The perfect fusion of architecture and nature creates
              a tranquil, resort-like atmosphere, making Damac Islands a
              destination in its own right.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection