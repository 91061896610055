import React from 'react'
import ProjectBanner from '../pages/projectDetail copy/components/ProjectBanner';
import FormBanner from './componets/FormBanner';
import AboutSection from '../pages/home/components/AboutSection';
import ImgSlider from './componets/ImgSlider';
import FloorPlan from './componets/FloorPlan';
import FloorPlanComponent from './componets/FloorPlanComponent';
import PaymentPlan from './componets/PaymentPlan';
import AmenitiesImg from './componets/AmenitiesImg';
import DownloadSection from './componets/DownloadSection';
import Banner360 from '../pages/home/components/Banner360';
import TalkSection from '../pages/home/components/TalkSection';

export const DamacIsland=()=> {
  return (
    <div>
      <div className="overflow-hidden">
        <ProjectBanner />
        <FormBanner />
        <AboutSection />
        <ImgSlider />
        <FloorPlanComponent />
        <PaymentPlan />
        <AmenitiesImg />
        <DownloadSection />
        <Banner360 />
        <TalkSection />
      </div>
    </div>
  );
}

export default DamacIsland